import React, { Component } from "react"
import { Link } from "gatsby"
import Page from "../components/Page"
import { graphql } from "gatsby"

import Layout from "../components/Layout2"
import Image from "../components/image"
import SEO from "../components/seo"
import StoryblokService from "../utils/storyblok-service"

export default class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      story: {
        content: this.props.data.story
          ? JSON.parse(this.props.data.story.content)
          : {},
      },
    }
  }

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search)
    let {
      data: { story },
    } = await StoryblokService.get(
      `cdn/stories/${this.props.data.story.full_slug}`
    )
    return story
  }

  async componentDidMount() {
    let story = await this.getInitialStory()
    if (story.content) this.setState({ story })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <Page blok={this.state.story.content} />
      </Layout>
    )
  }
}

export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "page-2" }) {
      name
      content
      full_slug
      uuid
    }
  }
`
